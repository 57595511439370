.parent{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: cornflowerblue;

}

.formCard{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    min-width: 20%;
}

.form{
    width:100% !important
}