html {
  zoom: 110%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.shadow-sm {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.shadow-md {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.shadow-lg {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 99;
  background: white;
  border-bottom: 1px solid grey;
}

.he-title {
  background-color: rgb(57, 57, 182);
  border-radius: 1rem;
  padding: 5px 15px;
  color: white !important;
}

.he-subtitle {
  background-color: rgb(12, 139, 12);
  border-radius: 1rem;
  padding: 5px 15px;
  color: white !important;
}

._2xcMq {
  font-size: 12px !important;
}