.parent {
  user-select: none;
}

.parent div:nth-child(2) {
}
.inputClassName {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714;
  list-style: none;
  font-size: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

.inputClassNameNoData {
  color: transparent !important;
}

.inputClassName:hover {
  border-color: #0390fc !important;
}

.inputClassName:focus {
  border-color: #0390fc !important;
  outline: none !important;
}

.clearIcon {
  display: none;
}

.parent:hover .clearIcon {
  display: inline-block !important;
}
