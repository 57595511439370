.uploadedImgContainer{
    position: relative;
    display: inline-block;
}

.uploadedImgContainerActions{
    position: absolute !important;
    top: 4 !important;
    right: 0 !important;
    scale: 0.6;
}

.uploadedImgContainer:hover .uploadedImgContainerActions{
    /* visibility: visible; */
    scale: 1;
}
